import i, { jsx as r } from "react/jsx-runtime";
import p from "moment-timezone";
import { i as f } from "../../styled-8b0035cf.js";
import { r as m } from "../../createSvgIcon-bd718fa8.js";
import { Box as _ } from "@mui/material";
import { styles as x } from "./DateTimeSelect.style.js";
var t = {}, b = f;
Object.defineProperty(t, "__esModule", {
  value: !0
});
var n = t.default = void 0, c = b(m()), l = i, j = (0, c.default)([/* @__PURE__ */ (0, l.jsx)("path", {
  d: "M17.59 18 19 16.59 14.42 12 19 7.41 17.59 6l-6 6z"
}, "0"), /* @__PURE__ */ (0, l.jsx)("path", {
  d: "m11 18 1.41-1.41L7.83 12l4.58-4.59L11 6l-6 6z"
}, "1")], "KeyboardDoubleArrowLeftOutlined");
n = t.default = j;
var a = {}, $ = f;
Object.defineProperty(a, "__esModule", {
  value: !0
});
var s = a.default = void 0, h = $(m()), d = i, w = (0, h.default)([/* @__PURE__ */ (0, d.jsx)("path", {
  d: "M6.41 6 5 7.41 9.58 12 5 16.59 6.41 18l6-6z"
}, "0"), /* @__PURE__ */ (0, d.jsx)("path", {
  d: "m13 6-1.41 1.41L16.17 12l-4.58 4.59L13 18l6-6z"
}, "1")], "KeyboardDoubleArrowRightOutlined");
s = a.default = w;
const q = ({ direction: e, distance: o, color: u, onSeek: v }) => /* @__PURE__ */ r(
  _,
  {
    sx: x.arrowIcon,
    title: `Seek ${p.duration(o, "seconds").humanize()} ${e === -1 ? "backward" : "forward"}`,
    onClick: () => v(e, o),
    children: e === -1 ? /* @__PURE__ */ r(n, { sx: { color: u } }) : /* @__PURE__ */ r(s, { sx: { color: u } })
  }
);
export {
  q as Seek
};
