import "./assets/style.css";
import { _ as n } from "./styled-8b0035cf.js";
const _ = "exact-prop: ​";
function w(e) {
  return process.env.NODE_ENV === "production" ? e : n({}, e, {
    [_]: (t) => {
      const s = Object.keys(t).filter((o) => !e.hasOwnProperty(o));
      return s.length > 0 ? new Error(`The following props are not supported: ${s.map((o) => `\`${o}\``).join(", ")}. Please remove them.`) : null;
    }
  });
}
const l = "_value_1dse4_3", r = "_valueText_1dse4_42", i = "_label_1dse4_47", u = "_multiChips_1dse4_57", d = "_chip_1dse4_64", c = "_menu_1dse4_80", p = "_options_1dse4_84", a = "_input_1dse4_101", v = "_error_1dse4_129", m = "_disabled_1dse4_133", h = "_group_1dse4_142", b = "_option_1dse4_84", f = "_selected_1dse4_169", x = "_icon_1dse4_173", C = {
  value: l,
  "value-outlined": "_value-outlined_1dse4_19",
  "value-filled": "_value-filled_1dse4_27",
  "value-none": "_value-none_1dse4_35",
  valueText: r,
  label: i,
  multiChips: u,
  chip: d,
  menu: c,
  options: p,
  input: a,
  error: v,
  disabled: m,
  group: h,
  option: b,
  selected: f,
  icon: x
};
export {
  w as e,
  C as s
};
